import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const mainRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('../../ui/pages/login/login.component').then(
        (mod) => mod.LoginComponent,
      ),
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('../../ui/pages/access-denied/access-denied.component').then(
        (mod) => mod.AccessDeniedComponent,
      ),
    canActivate: [MsalGuard],
  },  
];
