import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { userGuard } from './api/guards/user.guard';
import { permissionGuard } from './api/guards/permissions.guard';
import { Permission } from './api/models/permission';
import { AccessLevel, FunctionalityName } from './api/models/functionality';
import { promotionsRoutes } from './ui/routes/promotions.routes';
import { managementRoutes } from './ui/routes/management.routes';
import { mainRoutes } from './ui/routes/main.routes';

export const routes: Routes = [
  ...mainRoutes,
  ...promotionsRoutes,
  ...managementRoutes,
  {
    path: 'participant',
    loadComponent: () =>
      import('./ui/pages/participant/participant.component').then(
        (mod) => mod.ParticipantComponent,
      ),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      breadcrumb: 'FULL-PARTICIPANT.PARTICIPANT-TITLE',
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.VIEW_FULL_PARTICIPANT,
          level: AccessLevel.READ,
        },
      ],
    },
  },
  {
    path: 'user-administration',
    loadComponent: () =>
      import(
        './ui/pages/user-administration/user-administration.component'
      ).then((mod) => mod.UserAdministrationComponent),
    canActivate: [MsalGuard, userGuard, permissionGuard],
    data: {
      breadcrumb: 'USER_ADMINISTRATION.TITLE',
      permissions: <Permission[]>[
        {
          identifier: FunctionalityName.GRANT_PORTAL_ACCESS,
          level: AccessLevel.CREATE,
        },
        {
          identifier: FunctionalityName.LIST_ALL_USERS,
          level: AccessLevel.READ,
        },
      ],
    },
  },
];
