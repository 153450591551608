import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { permissionGuard } from '../../api/guards/permissions.guard';
import { userGuard } from '../../api/guards/user.guard';
import { FunctionalityName, AccessLevel } from '../../api/models/functionality';
import { Permission } from '../../api/models/permission';
import { promotionLabelResolver } from '../services/resolvers/promotion-label.resolver';

export const managementRoutes: Route[] = [
  {
    path: 'management',
    loadComponent: () =>
      import('../../ui/pages/default/default.component').then(
        (mod) => mod.DefaultComponent,
      ),
    data: {
      breadcrumb: 'MANAGEMENT.TITLE',
      disableBreadcrumb: true,
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../../ui/pages/management/management.component').then(
            (mod) => mod.ManagementComponent,
          ),
        canActivate: [MsalGuard, userGuard, permissionGuard],
        data: {
          permissions: <Permission[]>[
            {
              identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
              level: AccessLevel.READ,
            },
            {
              identifier: FunctionalityName.GRANT_VIEWING_PROMO_ACCESS,
              level: AccessLevel.READ,
            },
          ],
        },
      },
      {
        path: ':id',
        loadComponent: () =>
          import('../../ui/pages/default/default.component').then(
            (mod) => mod.DefaultComponent,
          ),
        data: {
          breadcrumb: 'MANAGEMENT_DASHBOARD.TITLE',
          disableBreadcrumb: false,
          breadcrumbShowCustomLabel: true,
        },
        resolve: {
          dynamicBreadcrumb: promotionLabelResolver,
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                '../../ui/pages/management-dashboard/management-dashboard.component'
              ).then((mod) => mod.ManagementDashboardComponent),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
                {
                  identifier: FunctionalityName.LIST_ALL_USERS,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
          {
            path: 'users',
            loadComponent: () =>
              import(
                '../../ui/pages/management-users/management-users.component'
              ).then((mod) => mod.ManagementUsersComponent),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'MANAGEMENT.USERS.TITLE',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
                {
                  identifier: FunctionalityName.LIST_ALL_USERS,
                  level: AccessLevel.READ,
                },
                {
                  identifier: FunctionalityName.GRANT_VIEWING_PROMO_ACCESS,
                  level: AccessLevel.UPDATE,
                },
                {
                  identifier: FunctionalityName.REMOVE_VIEWING_PROMO_ACCESS,
                  level: AccessLevel.UPDATE,
                },
              ],
            },
          },
          {
            path: 'prizes',
            loadComponent: () =>
              import(
                '../../ui/pages/management-prizes/management-prizes.component'
              ).then((mod) => mod.ManagementPrizesComponent),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'MANAGEMENT.PRIZE.TITLE',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_ALL_PRIZES,
                  level: AccessLevel.READ,
                },
                {
                  identifier: FunctionalityName.UPDATE_PRIZES_DATA,
                  level: AccessLevel.UPDATE,
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
