import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { PromotionsService } from '../../../api/services/promotions.service';
import { map } from 'rxjs';

export const promotionLabelResolver: ResolveFn<string> = (route) => {
  const promotionService = inject(PromotionsService);
  const promotionId = route.paramMap.get('id') ?? '';

  return promotionService.getPromotion(promotionId!).pipe(
    map((x) => {
      return `${x.id} / ${x.name}`;
    }),
  );
};
