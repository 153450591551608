import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { permissionGuard } from '../../api/guards/permissions.guard';
import { userGuard } from '../../api/guards/user.guard';
import { FunctionalityName, AccessLevel } from '../../api/models/functionality';
import { Permission } from '../../api/models/permission';
import { promotionLabelResolver } from '../services/resolvers/promotion-label.resolver';

export const promotionsRoutes: Route[] = [
  {
    path: 'promotions',
    loadComponent: () =>
      import('../../ui/pages/default/default.component').then(
        (mod) => mod.DefaultComponent,
      ),
    data: {
      breadcrumb: 'DASHBOARD.DASH-TITLE',
      disableBreadcrumb: true,
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../../ui/pages/dashboard/dashboard.component').then(
            (mod) => mod.DashboardComponent,
          ),
        canActivate: [MsalGuard, userGuard, permissionGuard],
        data: {
          permissions: <Permission[]>[
            {
              identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
              level: AccessLevel.READ,
            },
          ],
        },
      },
      {
        path: ':id',
        loadComponent: () =>
          import('../../ui/pages/default/default.component').then(
            (mod) => mod.DefaultComponent,
          ),
        data: {
          breadcrumb: 'AUDIT-PROMOTION.PAGE-TITLE',
          breadcrumbShowCustomLabel: true,
          disableBreadcrumb: false,
        },
        resolve: {
          dynamicBreadcrumb: promotionLabelResolver,
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('../../ui/pages/audit-promotion/audit-promotion.component').then(
                (mod) => mod.AuditPromotionComponent,
              ),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
          {
            path: 'promo-management',
            loadComponent: () =>
              import(
                '../../ui/pages/promotion-management/promotion-management.component'
              ).then((mod) => mod.PromotionManagementComponent),
              canActivate: [MsalGuard, userGuard, permissionGuard],
              data: {
                breadcrumb: 'AUDIT-PROMOTION.MANAGEMENT.TITLE',
                breadcrumbShowCustomLabel: false,
                permissions: <Permission[]>[
                  {
                    identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                    level: AccessLevel.READ,
                  },
                ],
              },
          },
          {
            path: 'participant',
            loadComponent: () =>
              import(
                '../../ui/pages/promotion-participant/promotion-participant.component'
              ).then((mod) => mod.ParticipantComponent),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'PROMOTION-PARTICIPANT.PARTICIPANT-TITLE',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOTION_PARTICIPANT,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
          {
            path: 'reports',
            loadComponent: () =>
              import('../../ui/pages/reports/reports.component').then(
                (mod) => mod.ReportsComponent,
              ),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'REPORTS.REPORTS-TITLE',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
          {
            path: 'reports-dashboard',
            loadComponent: () =>
              import(
                '../../ui/pages/reports-dashboard/reports-dashboard.component'
              ).then((mod) => mod.ReportsDashboardComponent),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'REPORTS-DASHBOARD.RESULTS',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  // identifier: FunctionalityName.VIEW_REPORTS_DASHBOARD,
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
          {
            path: 'pre-winners',
            loadComponent: () =>
              import('../../ui/pages/pre-winners/pre-winners.component').then(
                (mod) => mod.PreWinnersComponent,
              ),
            canActivate: [MsalGuard, userGuard, permissionGuard],
            data: {
              breadcrumb: 'AUDIT-PROMOTION.AUDIT-PRE-WINNERS.TITLE',
              breadcrumbShowCustomLabel: false,
              permissions: <Permission[]>[
                {
                  identifier: FunctionalityName.VIEW_PROMOS_WITH_GRANTED_ACCESS,
                  level: AccessLevel.READ,
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
